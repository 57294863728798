<template>
	<main-container :showHeader="false">
		<el-card>
			<div class="d-flex flex-row flex-wrap">
				<form-item-sites :abbr="true" :multiple="false" :local="true" v-model="queryForm.channel" :parent="false" @onload="q.channel=queryForm.channel;getList()" :clearable="false" @siteFullName="(v)=>selectedChannel=v"></form-item-sites>
				<form-item label="品类:">
					<el-input v-model="queryForm.category" size="mini"></el-input>
				</form-item>
				<form-item label="选款规则:" style="width:230px;">
					<el-select v-model="queryForm.rule" placeholder="请选择" size="mini" clearable>
						<el-option v-for="item in  recommendRules" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</form-item>
				<form-item label="商品id:" style="width:200px;">
					<el-input v-model="queryForm.spu" placeholder="请输入" size="mini"></el-input>
				</form-item>
				<form-item label="选择日期:" style="width:400px;">
					<el-date-picker v-model="queryForm.date" type="datetimerange" start-placeholder="开始日期"
						end-placeholder="结束日期" value-format="timestamp" style="width:340px;" size="mini" :clearable="false">
					</el-date-picker>
				</form-item>
				<form-item>
					<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">搜 索</el-button>
				</form-item>
				<form-item>
					<el-button type="primary" size="mini">下 载</el-button>
				</form-item>
				<form-item>
					<el-button type="primary" size="mini" @click="transitionToPage('google-ads_distincted-list')">返 回</el-button>
				</form-item>
			</div>
			<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);"></div>
				<div class="d-flex flex-row align-items-center">
					<el-button type="primary" size="mini" @click="doPreviewAds('config')" class="mr-2">预览广告</el-button>
					<upload-button action="/adms_api_v0/google/ads_post/single/import" accept=".csv" title="上传选款搭配" tooltip="csv文件,表头为'website,spu,category'"></upload-button>
				</div>
				<alert v-if="multipleSelection.length > 0" class="my-2">
					已选择<span class="text-primary d-inline-block mx-1">{{multipleSelection.length}}</span>个商品
				</alert>
				<ads-table ref="distincted-table" :tableData="tableData" @handleSelectionChange="handleSelectionChange" class="mt-3 w-100"></ads-table>
			<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
				:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
			</el-pagination>
			<ads-config-dialog platform="gg" :visible.sync="visible.adsConfig" @callback="(form)=>doPreviewAds('preview', form)" buttonTitle="">
			</ads-config-dialog>
			<ads-preview-dialog :visible.sync="visible.preview" :data="previewData" @callback="()=>doPreviewAds('publish')">
			</ads-preview-dialog>
		</el-card>
	</main-container>
</template>

<script>
	import Moment from 'moment'
	import Page from '@/common/mixins/page.js'
	import Common from '@/common/mixins/common.js'
	import Dialogs from '@/components/dialog'
	import AdsTable from '@/components/gg-display-ads/ads-table.vue'
	import Config from './mixins/config.js'
	import Data from './mixins/data.js'
	import { uuid } from 'vue-uuid';
	import {
		mapActions,
		mapState
	} from 'vuex'
	export default {
		mixins: [Page, Common, Config, Data],
		inject: ['adminLayout'],
		components: {
			...Dialogs,
			AdsTable
		},
		data() {
			return {
				visible: {
					adsConfig: false,
					preview: false
				},
				previewData: {}
			}
		},
		methods: {
			...mapActions([
				'previewGGAds',
				'publishGGAds',
				'fetchGGAdImgGroups'
			]),
			// Minix
			async getListUrl() {
				const trigger = this.trigger
				const payload = {
					params: {
						...this.page,
						...this.q
					},
					trigger
				}
				return this.$store.dispatch('google-ads/getListFromAdImage', payload)
			},
			handleList(content) {
				this.tableData = content.map(v => {
					const advImages = []
					if (v.splicingPicAuto !== null) {
						try {
							Object.keys(v.splicingPicAuto).forEach(k => {
								const size = {
									width: 100,
									height: 100
								}
								advImages.push({
									src: v.splicingPicAuto[k],
									alt: k,
									...size,
									id: uuid.v4()
								})
							})
						} catch (e) {
							console.error(e)
						}
					}
					return {...v, advImages}
				})
			},
			// Button Events
			doPreviewAds(type = 'config', data = null) {
				if (this.multipleSelection.length === 0) {
					return this.$showErrMsg('请先选择广告图')
				}
				switch (type) {
					case 'config':
						this.visible.adsConfig = true
						break
					case 'preview':
						this.visible.adsConfig = false
						this.adminLayout.showLoading()
						const payload = {
								body: {
									channel: this.queryForm.channel,
									...data,
									productIds: this.multipleSelection.map(v => {
										return {
											id: v.id,
											originPic: v.originPic
										}
									})
								}
							}
						this.$store.dispatch('google-ads/preview', payload)
							.then(res => {
								this.previewData = res
								this.adminLayout.hideLoading()
								this.visible.preview = true
							})
							.catch(err => {
								this.$showErrMsg(err)
								this.adminLayout.hideLoading()
							})
						break
					case 'publish':
						this.adminLayout.showLoading(true)
						this.$store.dispatch('google-ads/publish', this.previewData.id)
							.then(res => {
								this.$refs['distincted-table'].$refs.multipleTable.clearSelection()
								this.visible.preview = false
								this.adminLayout.hideLoading()
							})
							.catch(err => {
								this.$showErrMsg(err)
								this.adminLayout.hideLoading()
							})
					default:
						break
				}
			}
		}
	}
</script>
