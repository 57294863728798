<template>
	<el-table height="auto" ref="multipleTable" :data="tableData" @selection-change="handleSelectionChange" @select="pinSelect">
		<el-table-column type="selection" width="55" :selectable="rowSelectable">
		</el-table-column>
		<el-table-column label="商品id" prop="spu" min-width="120">
		</el-table-column>
		<el-table-column label="站点" prop="channel" min-width="120">
		</el-table-column>
		<el-table-column label="选款规则" prop="rule" min-width="150">
			<template slot-scope="scope">Manual</template>
		</el-table-column>
		<el-table-column label="品类" prop="category" min-width="150">
		</el-table-column>
		<el-table-column label="商品主图" min-width="400">
			<template slot-scope="scope">
				<div class="d-flex flex-row align-items-center">
					<div v-for="image in scope.row.mainImages" class="table-img-box mr-1">
						<el-image :src="image" :preview-src-list="scope.row.mainImages"></el-image>
					</div>
				</div>
			</template>
		</el-table-column>
		<el-table-column label="广告图片" min-width="300">
			<template slot-scope="scope">
				<div class="adv-images-container">
					<div class="d-flex flex-row flex-nowrap adv-images">
						<div :id="'img-box-' + image.id" v-for="image in scope.row.advImages" class="mr-1 adv-image" :style="advImgStyle(image)">
							<el-tooltip :content="image.alt">
								<el-image style="width:100%;height:100%;" :src="image.src" :alt="image.alt" :preview-src-list="scope.row.advImages.map(v => v.src)" fit="contain" @load="updateAdvImgStyle($event, image.id)"></el-image>
							</el-tooltip>
						</div>
					</div>
				</div>
			</template>
		</el-table-column>
		<!-- <el-table-column label="操作" min-width="80">
			<template slot-scope="scope">
				<update-img-button :data="scope.row"></update-img-button>
			</template>
		</el-table-column> -->
	</el-table>
</template>

<script>
	import MultipleTableSelection from '@/common/mixins/multiple-table-selection.js'
	export default {
		mixins: [MultipleTableSelection],
		props: {
			tableData: {
				type: Array,
				default: []
			}
		},
		methods: {
			rowSelectable(row, index) {
				return (row.advImages || []).length > 0
			},
			advImgStyle(image) {
				return {
					width: image.width + 'px',
					height: image.height + 'px',
					transtion: '0.1s linear'
				}
			},
			updateAdvImgStyle(e, id) {
				document.querySelector('#img-box-' + id).style.width = 'auto'
			}
		}
	}
</script>
<style lang="scss">
	.adv-images-container {
		overflow-x:auto;
		overflow-y:hidden;
		.adv-images {
			width:max-content;
			padding: 5px;
		}
		.adv-image:hover {
			transition: 0.1s linear;
			box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
		}
	}
	.adv-images-container::-webkit-scrollbar {
		height: 2px;
	}
</style>
